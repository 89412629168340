<template>
  <div class="quesTion">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        :title="item.title"
        :name="item.id"
        v-for="(item, index) in PlaceContents"
        :key="index"
      >
        <div class="content" v-html="item.content"></div>
      </el-collapse-item>
    </el-collapse>
    <div class="but-box">
      <el-button type="primary" @click="isOk"> 返回列表</el-button>
      <el-button type="danger" @click="golearn">练习真题</el-button>
    </div>
  </div>
</template>
<script>
import { getInfo, getToken } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
import classKcChoose from "@/components/classKcChoose.vue";
export default {
  components: { classKcChoose },
  props: ["id"],

  data() {
    return {
      detail: {},
      PlaceContents: [],
      tabId: null,
      userInfo: {},
      activeNames: ["1"], //当前打开
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.getDetail();
  },
  methods: {
    /* 获取详情 */
    async getDetail() {
      if (!this.id) {
        return;
      }
      answerClient
        .getPcPlaceDetails(
          this.id,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.detail = res.data.Place;
          this.PlaceContents = res.data.PlaceContents;
        });
    },
    /* 返回列表 */
    isOk() {
      this.$emit("isOk");
    },
    /* 开始答题 */
    golearn(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法学习, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      if (this.detail.showStatus == 2 && this.detail.tenantPrice > 0) {
        this.$notify({
          title: "错误",
          message: "未购买，无法学习, 请先去购买",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/all/examPlace/answer?Id=${this.id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.quesTion {
  /*   position: absolute;
  top: 0px;
  left: 0px;
  right: 0px; */
  /*   bottom: 0px; */
  background: #fff;
  padding: 28px;
  /deep/.el-collapse {
    border: none;
    .el-collapse-item__header {
      border: none;
    }
  }
  .content {
    background: #f9f9f9;

    padding: 16px;
  }
}
.but-box {
  margin: 60px auto 20px;
  width: fit-content;
}
</style>
