<template>
  <!-- 收费类型 -->
  <div>
    <div class="typelist">
      <div
        v-if="showAll"
        class="item"
        @click="clas()"
        :class="[!search.type ? 'on' : '']"
      >
        全部
        <div class="item-line" v-if="!search.type"></div>
      </div>
      <div
        class="item"
        v-for="(item, index) in classfiList"
        :key="index"
        @click="clas(item.fId)"
        :class="[search.type == item.fId ? 'on' : '']"
      >
        {{ item.fName }}
        <div class="item-line" v-if="search.type == item.fId"></div>
      </div>
    </div>
    <div
      class="typelist"
      v-if="type == 7 && search.type != 4001 && search.type != 4002"
    >
      <div
        class="item"
        :class="search.examType == 1 ? 'active' : ''"
        @click="exam(1)"
      >
        试卷包
      </div>
      <div
        class="item"
        :class="search.examType == 2 ? 'active' : ''"
        @click="exam(2)"
      >
        试卷
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  // props: ["showAll", "type"],
  props: {
    showAll: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
    },
    other: {
      type: Array,
    },
  },
  data() {
    return {
      search: {
        type: null,
        examType: 1,
      },

      classfiList: [],
    };
  },
  async created() {
    await Vue.prototype.classSelectSelect(this.type).then((res) => {
      this.classfiList = res;
    });
    if (this.other) {
      this.classfiList = await this.classfiList.concat(this.other);
    }
    if (!this.showAll) {
      this.search.type = this.classfiList[0].fId;
      this.$emit("isOk", this.search);
    }
  },
  methods: {
    clas(val) {
      this.search.type = val;
      if (this.search.type == 4003) {
        this.$router.push("/all/examPlace/answer?type=2");
      } else {
        this.$emit("isOk", this.search);
      }
    },
    exam(val) {
      this.search.examType = val;
      this.$emit("isOk", this.search);
    },
  },
};
</script>
<style lang="less" scoped>
.typelist {
  width: 100%;
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(216, 216, 216, 0.7);

  padding: 0px 30px;
  display: flex;
  .item {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 62px;
    color: #999999;

    cursor: pointer;
    margin-right: 28px;
    position: relative;
  }
  .on {
    color: #333333;
  }
  .active {
    color: #ff4027;
  }
  .item-line {
    margin: 0px auto;
    width: 26px;
    height: 2px;
    background: #ff4027;

    border-radius: 1px;
    position: absolute;
    bottom: 6px;
    left: calc(50% - 13px);
  }
}
</style>
